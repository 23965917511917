<template>
  <div class="modal-inner-container">
    <div class="w-full">
      <div class="text-n-4xl font-bold">
        {{$t('security.twofa.add_twofa')}}
      </div>

      <div class="text-n-md font-medium mt-n-5xl">
        {{$t('security.twofa.add_your_secure_key')}}
      </div>
    </div>

    <qrcode
      class="self-center mt-n-5xl"
      :value="otpauthUrl"
      :options="{ margin: 4, width: 215 }"
    />

    <div class="w-full mt-s64">
      <ButtonV2
        @onClick="continueAction"
        :label="$t('continue')"
        testId="btn-continue"
        wide
      />
      <ButtonV2
        @onClick="addManually"
        :label="$t('security.twofa.add_manually')"
        testId="btn-add-manually"
        class="mt-n-2xl"
        version="secondary"
        wide
      />
    </div>
  </div>
</template>

<script>
  import ButtonV2 from '@/stories/misc/ButtonV2';

  export default {
    name: 'EnableTwoFactorAuthentication',
    components: {
      ButtonV2,
    },

    async mounted() {
      await this.$store.dispatch('events/track', {
        event: 'TWO_FACTOR_ENABLE_START_VIEWED',
      });
    },

    props: {
      otpauthUrl: {
        type: String,
        required: true,
      },
      secret: {
        type: String,
        required: true,
      },
      recovery: {
        type: String,
        required: true,
      },
    },
    methods: {
      continueAction() {
        const { recovery } = this.$props;

        this.showModal('TwoFactorAuthenticationBackupCode', { recovery });
      },
      addManually() {
        const { secret, recovery } = this.$props;

        this.showModal('YourSecureKey', { secret, recovery });
      },
    }
  };
</script>

<style scoped>
</style>
